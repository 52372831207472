.person-card {
    &--deleted {
        opacity: 0.5;
        filter: blur(5px);

        &:hover {
            animation: fade-in 0.5s ease-in-out;
            opacity: 1.0;
            filter: blur(0);
        }

        &:not(:hover) {
            animation: fade-out 0.5s ease-in-out;
        }
    }
}

@keyframes fade-in {
    0% {
        opacity: 0.5;
        filter: blur(5px)
    }

    100% {
        opacity: 1.0;
        filter: blur(0)
    }
}

@keyframes fade-out {
    0% {
        opacity: 1.0;
        filter: blur(0)
    }

    100% {
        opacity: 0.5;
        filter: blur(5px)
    }
}